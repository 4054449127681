import React from 'react';
import './_Portfolio.scss';

const Portfolio = () => {
	return (
		<div>
        </div>
	);
};

export default Portfolio;
