import React, { Fragment } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';

import {
	App as Container,
	MainWallet,
	CurrencyWallet,
	Home,
	Deposit,
	Withdraw,
	TransactionsHistory,
	Trade,
	ChartEmbed,
	AddTradeTabs,
	Stake,
	AppWrapper as AdminContainer,
	CoinPage,
} from './containers';

import store from './store';
import { setLanguage } from './actions/appActions';

import {
	isLoggedIn,
	getToken,
	removeToken,
	getTokenTimestamp,
	isAdmin,
} from './utils/token';
import {
	getLanguage,
	getInterfaceLanguage,
	getLanguageFromLocal,
} from './utils/string';
import { getExchangeInitialized, getSetupCompleted } from './utils/initialize';
import CreateAsset from 'containers/Create/CreateAsset';
import CreatePair from 'containers/Create/CreatePair';
import { withRouter } from 'utils/router';
import { NETWORK } from 'config/constants';
import { Result } from 'antd';

ReactGA.initialize('UA-154626247-1'); // Google analytics. Set your own Google Analytics values

let lang = getLanguage();
if (!lang) {
	lang = getInterfaceLanguage();
}

if (getLanguageFromLocal()) {
	store.dispatch(setLanguage(lang));
}

function loggedIn(nextState, replace) {
	const initialized = getExchangeInitialized();
	const setup_completed = getSetupCompleted();
	if (
		initialized === 'false' ||
		(typeof initialized === 'boolean' && !initialized)
	) {
		replace({
			pathname: '/init',
		});
	} else if (
		!isLoggedIn() &&
		(setup_completed === 'false' ||
			(typeof setup_completed === 'boolean' && !setup_completed))
	) {
		replace({
			pathname: '/admin-login',
		});
	} else if (
		isLoggedIn() &&
		isAdmin() &&
		(setup_completed === 'false' ||
			(typeof setup_completed === 'boolean' && !setup_completed))
	) {
		replace({
			pathname: '/admin',
		});
	} else if (isLoggedIn()) {
		replace({
			pathname: '/account',
		});
	}
}

const logOutUser = () => {
	if (getToken()) {
		removeToken();
	}
};

const noAuthRoutesCommonProps = {
	onEnter: loggedIn,
};

const noLoggedUserCommonProps = {
	onEnter: logOutUser,
};

export const GAReporter = (props) => {
    let location = useLocation();

    React.useEffect(() => {
        if (window) {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);
    }, [location]);

    return props.children;
};

export const generateRoutes = (routes = []) => {
    if (NETWORK == 'mainnet') {
        return (
            <>
            <GAReporter />
                <Routes>
                    <Route path="lang/:locale" element={<createLocalizedRoutes/>} />
                    <Route element={<Container/>}>
                        <Route path="/" element={<Navigate to="/stake" />} />
                        <Route
                            path="stake"
                            name="Stake"
                            element={<Stake/>}
                        />
                    </Route>
                    <Route path="*" element={<Navigate to="/stake" />} />
                </Routes>
            </>
        );
    }

	return (
        <>
            <GAReporter />
            <Routes>
                <Route path="lang/:locale" element={<createLocalizedRoutes/>} />
                <Route element={<Container/>}>
                    <Route path="/" name="Markets" element={<AddTradeTabs/>} />
                    <Route path="/referral" name="Referral" element={<Home/>} />
                    <Route path="/list-token" name="List Token Tabs" element={<CreateAsset/>} />
                    <Route path="/list-pair" name="List Pair Tabs" element={<CreatePair/>} />
                    <Route
                        path="/chart-embed/:pair"
                        name="ChartEmbed"
                        element={<ChartEmbed/>}
                    />
                    <Route
                        path="transactions"
                        name="Transactions"
                        element={<TransactionsHistory/>}
                    />
                    <Route path="trade/:pair" name="Trade" element={<Trade/>} />
                    <Route path="markets" name="Trade Tabs" element={<AddTradeTabs/>} />
                    <Route path="wallet" name="Wallet" element={<MainWallet/>} />
                    <Route
                        path="wallet/:currency/deposit"
                        name="Deposit"
                        element={<Deposit/>}
                        // onEnter={requireAuth}
                    />
                    <Route
                        path="wallet/:currency/withdraw"
                        name="Withdraw"
                        element={<Withdraw/>}
                        // onEnter={requireAuth}
                    />
                    <Route
                        path="assets/coin/:token"
                        name="Coin Page"
                        element={<CoinPage/>}
                    />
                    <Route
                        path="wallet/:currency"
                        name="Wallet"
                        element={<CurrencyWallet/>}
                        // onEnter={requireAuth}
                    />
                    <Route
                        path="stake"
                        name="Stake"
                        element={<Stake/>}
                    />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </>
	);
};
