import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { wagmiUseStakeInfoHOC } from 'utils/wagmiWrapper';
import DesktopStake from './DesktopStake';
import MobileStake from './MobileStake';

const Index = (props) => {
	return (
        <Fragment>
            { 
                // isMobile ? 
                // <MobileStake {...props}  /> : 
                <DesktopStake {...props}  />
            }
        </Fragment>
    );
};

export default wagmiUseStakeInfoHOC(Index);
