import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withConfig from 'components/ConfigProvider/withConfig';
import { requestConstant, setSnackNotification } from 'actions/appActions';
import STRINGS from 'config/localizedStrings';

import MovePrompt from './MovePrompt';
import { withRouter } from 'utils/router';
import { wagmiUseAccountHOC } from 'utils/wagmiWrapper';

const CONTENT_TYPE = {
	PROMPT: 'PROMPT',
};

class MoveXHTContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: CONTENT_TYPE.PROMPT,
		};
	}

	onCopy = () => {
		const { icons: ICONS, setSnackNotification } = this.props;
		setSnackNotification({
			icon: ICONS.COPY_NOTIFICATION,
			content: STRINGS['COPY_SUCCESS_TEXT'],
		});
	};

	onProceed = () => {
		const { router, account, onCloseDialog } = this.props;
        requestConstant().then(ret => {
            const constants = ret.data;
            const stakablesInfo = constants.stakables;
            router.navigate(`/wallet/${stakablesInfo.coin}/withdraw`);
        });
		onCloseDialog();
	};

	renderContent = (type) => {
		const { account, onCloseDialog } = this.props;

		switch (type) {
			case CONTENT_TYPE.PROMPT:
			default:
				return (
					<MovePrompt
						account={account.address}
						onBack={onCloseDialog}
						onProceed={this.onProceed}
						onClose={onCloseDialog}
						onCopy={this.onCopy}
					/>
				);
		}
	};

	render() {
		const { type } = this.state;

		return <div className="w-100">{this.renderContent(type)}</div>;
	}
}

const mapStateToProps = (store) => ({
	// account: store.stake.account,
});

const mapDispatchToProps = (dispatch) => ({
	setSnackNotification: bindActionCreators(setSnackNotification, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(wagmiUseAccountHOC(withRouter(withConfig(MoveXHTContent))));
