import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { hash } from 'rsvp';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import merge from 'lodash.merge';
import ConfigProvider from 'components/ConfigProvider';
import EditProvider from 'components/EditProvider';
import defaultConfig from 'config/project.config';
import './config/initialize';
import { addElements, injectHTML } from 'utils/script';

import 'flag-icon-css/css/flag-icon.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import store, {persistor} from './store';
import { generateRoutes } from './routes';
import '../node_modules/rc-tooltip/assets/bootstrap_white.css'; // eslint-disable-line
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {
	setLocalVersions,
	getLocalVersions,
	initializeStrings,
	setValidLanguages,
	setExchangeInitialized,
	setSetupCompleted,
	setBaseCurrency,
	setDefaultLogo,
	consoleKitInfo,
	getContracts,
	modifySections,
} from 'utils/initialize';
import { getKitData } from 'actions/operatorActions';
import { setOrderbookWidth, setPairsData } from 'actions/orderbookAction';
import {
	requestConstant,
	setHomePageSetting,
	setInjectedValues,
	setInjectedHTML,
	requestPlugins,
	setWebViews,
	setPlugins,
	setInfo,
	setConfig,
	changeTheme,
	setLanguage,
	changePair,
	setPairs,
	setCurrencies,
	setBitlyABI,
	setPairABI,
	setERC20ABI,
	setUserPayments,
	setOnramp,
	setOfframp,
	setOrderLimits,
	setHelpdeskInfo,
	setContracts,
	setAllContracts,
	setBroker,
	setTransactionLimits,
	setQuickTrade,
	setAdminSortData,
	setAdminWalletSortData,
	setAdminDigitalAssetsSortData,
	setSortModeChange,
	setSortModeVolume,
	SORT,
	WALLET_SORT,
	DIGITAL_ASSETS_SORT,
	setContractsAddress,
	requestBitlyABI,
	requestERC20ABI,
	requestPairABI,
	setMarkets,
    closeAllNotification,
} from 'actions/appActions';
import { hasTheme } from 'utils/theme';
import { generateRCStrings } from 'utils/string';
import {
	LANGUAGE_KEY,
	DEFAULT_PINNED_COINS,
	projectId,
    wagmiConfig,
} from 'config/constants';
import {
	consolePluginDevModeInfo,
	mergePlugins,
	IS_PLUGIN_DEV_MODE,
} from 'utils/plugin';
import { drawFavIcon } from 'helpers/vanilla';
import { setupManifest } from 'helpers/manifest';
import {
	hideBooting,
	showBooting,
	setLoadingImage,
	setLoadingStyle,
} from 'helpers/boot';
import { filterPinnedAssets, handleUpgrade } from 'utils/utils';
import AppStateListener from 'appStateListener';

// For Web3
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { setLogin } from 'actions/authAction';
import { getAccountAddress } from 'utils/wagmiWrapper';

// 0. Setup queryClient
const queryClient = new QueryClient();

export function Web3ModalProvider({ children }) {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
};

consoleKitInfo();
consolePluginDevModeInfo();

const getConfigs = async () => {
	const localVersions = getLocalVersions();
    getAccountAddress().then(address=>{
        setLogin(address!=undefined);
    });

	localStorage.removeItem('initialized');
	const kitData = await getKitData();
	const {
		meta: {
			versions: remoteVersions = {},
			sections = {},
			default_sort = SORT.CHANGE,
			pinned_markets = [],
			default_wallet_sort = WALLET_SORT.AMOUNT,
			pinned_assets = [],
			default_digital_assets_sort = DIGITAL_ASSETS_SORT.CHANGE,
		} = {},
		valid_languages = '',
		info: { initialized },
		setup_completed,
		native_currency,
		logo_image,
		features: { home_page = false } = {},
		injected_values = [],
		injected_html = {},
		captcha = {},
		defaults = {},
	} = kitData;

	store.dispatch(setConfig(kitData));
	if (defaults) {
		const themeColor = localStorage.getItem('theme');
		const isThemeValid = hasTheme(themeColor, kitData.color);
		const language = localStorage.getItem(LANGUAGE_KEY);

		if (defaults.theme && (!themeColor || !isThemeValid)) {
			store.dispatch(changeTheme(defaults.theme));
			localStorage.setItem('theme', defaults.theme);
		}

		if (!language && defaults.language) {
			store.dispatch(setLanguage(defaults.language));
		}
	}
	if (kitData.info) {
		store.dispatch(setInfo({ ...kitData.info }));
	}

	kitData['sections'] = modifySections(sections);

	const promises = {};
	Object.keys(remoteVersions).forEach((key) => {
		const localVersion = localVersions[key];
		const remoteVersion = remoteVersions[key];

		if (localVersion !== remoteVersion) {
			promises[key] = kitData[key];
		} else {
			promises[key] = JSON.parse(localStorage.getItem(key) || '{}');
		}
	});

	const remoteConfigs = await hash(promises);
	Object.keys(remoteConfigs).forEach((key) => {
		if (key === 'color') {
			Object.entries(remoteConfigs[key]).forEach(([themeKey, themeObj]) => {
				if (typeof themeObj !== 'object') {
					delete remoteConfigs[key][themeKey];
				}
			});
		}
		localStorage.setItem(key, JSON.stringify(remoteConfigs[key]));
	});

	const { data: constants = {} } = await requestConstant();
	const { coins: coin_icons = {} } = constants;
	const {
		app: { pair },
	} = store.getState();

	// if (!pair) {
	// 	const initialPair = Object.keys(constants.pairs)[0];
	// 	store.dispatch(changePair(initialPair));
	// }

	// store.dispatch(setCurrencies(constants.coins));
	store.dispatch(setUserPayments(kitData.user_payments));
	store.dispatch(setOnramp(kitData.onramp));
	store.dispatch(setOfframp(kitData.offramp));
	store.dispatch(setPairs({}));
	store.dispatch(setMarkets([]));
	store.dispatch(setPairsData(constants.pairs));
	store.dispatch(setContracts(getContracts(constants.coins)));
	store.dispatch(setAllContracts(constants));
	store.dispatch(setBroker(constants.broker));
	store.dispatch(setQuickTrade(constants.quicktrade));
	store.dispatch(setTransactionLimits(constants.transactionLimits));
	store.dispatch(setContractsAddress(constants.contracts));
	// store.dispatch(setPricesAndAsset({}, constants.coins));

	const { data: bitlyABI = {} } = await requestBitlyABI();
	const { data: pairABI = {} } = await requestPairABI();
	const { data: ERC20ABI = {} } = await requestERC20ABI();
	store.dispatch(setBitlyABI(bitlyABI));
	store.dispatch(setPairABI(pairABI));
	store.dispatch(setERC20ABI(ERC20ABI));

	const orderLimits = {};
	Object.keys(constants.pairs).forEach((pair) => {
		orderLimits[pair] = {
			PRICE: {
				MIN: constants.pairs[pair].min_price,
				MAX: constants.pairs[pair].max_price,
				STEP: constants.pairs[pair].increment_price,
			},
			SIZE: {
				MIN: constants.pairs[pair].min_size,
				MAX: constants.pairs[pair].max_size,
				STEP: constants.pairs[pair].increment_price,
			},
		};
	});
	store.dispatch(setOrderLimits(orderLimits));

	setDefaultLogo(logo_image);
	setBaseCurrency(native_currency);
	setLocalVersions(remoteVersions);
	setValidLanguages(valid_languages);
	setExchangeInitialized(initialized);
	setSetupCompleted(setup_completed);
    store.dispatch(closeAllNotification());

	const isBasic = handleUpgrade(kitData.info);
	const pinnedCoins = filterPinnedAssets(
		isBasic ? DEFAULT_PINNED_COINS : pinned_assets,
		constants.coins
	);

	store.dispatch(setHomePageSetting(home_page));
	store.dispatch(setInjectedValues(injected_values));
	store.dispatch(setInjectedHTML(injected_html));
	store.dispatch(setAdminSortData({ pinned_markets, default_sort }));
	store.dispatch(
		setAdminWalletSortData({ pinned_assets: pinnedCoins, default_wallet_sort })
	);
	store.dispatch(
		setAdminDigitalAssetsSortData({
			pinned_assets: pinnedCoins,
			default_digital_assets_sort,
		})
	);
    store.dispatch(
        setOrderbookWidth(1000)
    );

	if (default_sort === SORT.VOL) {
		store.dispatch(setSortModeVolume());
	} else {
		store.dispatch(setSortModeChange());
	}

	const appConfigs = merge({}, defaultConfig, remoteConfigs, {
		coin_icons,
		captcha,
		valid_languages,
		defaults,
	});
	setLoadingStyle(appConfigs);
	setLoadingImage(appConfigs);

	try {
		const {
			data: { data: plugins = [] } = { data: [] },
		} = await requestPlugins();

		const allPlugins = IS_PLUGIN_DEV_MODE
			? await mergePlugins(plugins)
			: plugins;

		store.dispatch(setPlugins(allPlugins));
		store.dispatch(setWebViews(allPlugins));
		store.dispatch(setHelpdeskInfo(allPlugins));
	} catch (err) {
		console.error(err);
		showBooting();
		throw err;
	}

	const {
		app: { plugins_injected_html },
	} = store.getState();

	return [appConfigs, injected_values, injected_html, plugins_injected_html];
};


const bootstrapApp = (
	appConfig,
	injected_values,
	injected_html,
	plugins_injected_html
) => {
	const {
		icons: {
			dark: { EXCHANGE_FAV_ICON = '/favicon.ico' },
		},
	} = appConfig;
	addElements(injected_values, 'head');
	injectHTML(injected_html, 'head');
	injectHTML(plugins_injected_html, 'head');
	// drawFavIcon(EXCHANGE_FAV_ICON);
	// window.appConfig = { ...appConfig }
	const {
		app: {
			remoteRoutes,
			plugins,
			constants: { api_name: name },
		},
	} = store.getState();

	const RCStrings = generateRCStrings(plugins);
	const mergedStrings = merge({}, RCStrings, appConfig.strings);
	setupManifest({ name, short_name: name });

	initializeStrings(mergedStrings);

	const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(
        // <React.StrictMode>
        <BrowserRouter>
            <Web3ModalProvider>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <EditProvider>
                            <ConfigProvider initialConfig={appConfig}>
                                <AppStateListener>
                                    { generateRoutes() }
                                </AppStateListener>
                            </ConfigProvider>
                        </EditProvider>
                    </PersistGate>
                </Provider>
            </Web3ModalProvider>
        </BrowserRouter>
        // </React.StrictMode>
    );
};

const initialize = async () => {
	try {
		const [
			configs,
			injected_values,
			injected_html,
			plugins_injected_html,
		] = await getConfigs();
		bootstrapApp(
			configs,
			injected_values,
			injected_html,
			plugins_injected_html
		);
		hideBooting();
	} catch (err) {
		console.error('Initialization failed!\n', err);
		setTimeout(initialize, 3000);
	}
};

initialize().then(() => {});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
