import React, { Component } from 'react';
import { connect } from 'react-redux';
import mathjs from 'mathjs';
import { bindActionCreators } from 'redux';
import { approve, addStake } from 'actions/stakingActions';
import withConfig from 'components/ConfigProvider/withConfig';
import {
	generateTableData,
	getAllUserStakes,
	getPendingTransactions,
	getTokenAllowance,
} from 'actions/stakingActions';
import { open } from 'helpers/link';
import { STAKING_INDEX_COIN } from 'config/contracts';
import { DEFAULT_COIN_DATA } from 'config/constants';
import { formatToCurrency, toFixed } from 'utils/currency';
import { getDecimals } from 'utils/utils';
import { minValue, maxValue, required } from 'components/Form/validations';
import STRINGS from 'config/localizedStrings';

import AllowanceLoader from './AllowanceLoader';
import AmountContent from './AmountContent';
import PeriodContent from './PeriodContent';
import ReviewContent from './ReviewContent';
import WaitingContent from './WaitingContent';
import SuccessfulStakeContent from './SuccessfulStakeContent';
import ErrorContent from './ErrorContent';
import { getChainConfig, getCurrentChain, getSubscribeMode, wagmiUseStakeInfoHOC } from 'utils/wagmiWrapper';
import Manage from './Manage';

const CONTENT_TYPE = {
	AMOUNT: 'AMOUNT',
	PERIOD: 'PERIOD',
	REVIEW: 'REVIEW',
	WAITING: 'WAITING',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	LOADING: 'LOADING',
    SELECT: 'SELECT',
};

const ACTION_TYPE = {
	STAKE: 'STAKE',
	WITHDRAW: 'WITHDRAW',
    CLAIM: 'CLAIM',
    COMPOUND: 'COMPOUND'
};

class StakeContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: CONTENT_TYPE.SELECT,
			amount: '',
			period: '',
			action: ACTION_TYPE.STAKE,
			pending: false,
			isValid: false,
			error: '',
		};
	}

	setPeriod = ({ target: { value: period } }) => {
		this.setState({ period });
	};

	parse = (value = '') => {
		const { coins, tokenData } = this.props;
		const { symbol } = tokenData;
		const { increment_unit } = coins[symbol] || DEFAULT_COIN_DATA;
		const decimal = getDecimals(increment_unit);
		const decValue = toFixed(value);
		const valueDecimal = getDecimals(decValue);

		let result = value;
		if (decimal < valueDecimal) {
			result = decValue
				.toString()
				.substring(0, decValue.toString().length - (valueDecimal - decimal));
		}
		return result;
	};

	setAmount = ({ target: { value: amount } }) => {
		const {
			depositInfo,
            balance,
            stakables,
            coins,
		} = this.props;
        const {
            action
        } = this.state;

        const address = stakables.coin;
        const balance_in_wallet = balance?.[`${address}_in_wallet`];
        const display_name = coins?.[address]?.display_name;
        const balance_in_stake = depositInfo?.[0];
        const available = action == ACTION_TYPE.STAKE ? balance_in_wallet : balance_in_stake;

		if (amount) {
			if (mathjs.larger(amount, available)) {
				this.setState({
					amount,
					isValid: false,
					error: maxValue(
						available,
						STRINGS.formatString(
							STRINGS['WITHDRAWALS_LOWER_BALANCE'],
							`${amount} ${display_name}`
						)
					)(amount),
				});
			} else if (mathjs.smaller(amount, 0.01)) {
				this.setState({
					amount,
					isValid: false,
					error: minValue(0.01, STRINGS['WITHDRAWALS_MIN_VALUE_ERROR'])(amount),
				});
			} else {
				this.setState({
					amount,
					isValid: true,
					error: '',
				});
			}
		} else {
			this.setState({
				amount,
				isValid: false,
				error: required(amount),
			});
		}
	};

	approveAndStake = (symbol) => async ({ amount, period, account }) => {
		const {
			generateTableData,
			getAllUserStakes,
			getPendingTransactions,
		} = this.props;

		this.setContent(CONTENT_TYPE.LOADING);

		try {
			const allowance = await getTokenAllowance(symbol)(account);
			if (mathjs.largerEq(allowance, amount)) {
				this.setAction(ACTION_TYPE.STAKE, false);
				this.setContent(CONTENT_TYPE.WAITING);
				await addStake(symbol)({
					amount,
					period,
					account,
					cb: () => this.setAction(ACTION_TYPE.STAKE, true),
				});
				await Promise.all([
					generateTableData(account),
					getAllUserStakes(account),
					getPendingTransactions(account),
				]);
				this.setContent(CONTENT_TYPE.SUCCESS);
			} else {
				this.setAction(ACTION_TYPE.WITHDRAW, false);
				this.setContent(CONTENT_TYPE.WAITING);

				await approve(symbol)({
					amount,
					account,
					cb: () => this.setAction(ACTION_TYPE.WITHDRAW, true),
				});
				this.setAction(ACTION_TYPE.STAKE, false);
				await addStake(symbol)({
					amount,
					period,
					account,
					cb: () => this.setAction(ACTION_TYPE.STAKE, true),
				});
				await Promise.all([
					generateTableData(account),
					getAllUserStakes(account),
					getPendingTransactions(account),
				]);
				this.setContent(CONTENT_TYPE.SUCCESS);
			}
		} catch (err) {
			console.error(err);
			this.setContent(CONTENT_TYPE.ERROR);
		}
	};

    onProceed = async () => {
        const {
			deposit,
            withdraw,
		} = this.props;

        const {
			amount,
            action
		} = this.state;

        let ret;
		this.setContent(CONTENT_TYPE.WAITING);
        if (action == ACTION_TYPE.STAKE) {
            ret = await deposit(amount);
        } else {
            ret = await withdraw(amount);
        }

        if (ret.ok) {
            this.setContent(CONTENT_TYPE.SUCCESS);
            getSubscribeMode().then(mode=>{
                if (mode == 'trigger') {
                    getChainConfig().then(chain=>{
                        const callbacks = () => {
                            this.props.triggerCallbacks.fetchBalances();
                        };
                        setTimeout(callbacks, chain.block_rate*1000);
                    });
                }
            });
            // this.props.triggerCallbacks.setStakeInfo();
        } else {
            this.setAction(ret.res, false);
            this.setContent(CONTENT_TYPE.ERROR);
        }
    };

    onClickStake = (isStake=true) => {
        this.setAction(isStake ? ACTION_TYPE.STAKE : ACTION_TYPE.WITHDRAW, false);
        this.setContent(CONTENT_TYPE.AMOUNT);
    };

    onCLickClaim = async (isCompound=false) => {
        const {
			stakeRewards,
            claimRewards,
		} = this.props;

		this.setAction(isCompound ? ACTION_TYPE.COMPOUND : ACTION_TYPE.CLAIM, false);
        this.setContent(CONTENT_TYPE.WAITING);

        let ret;
        if (isCompound) {
            ret = await stakeRewards();
        } else {
            ret = await claimRewards();
        }

        if (ret.ok) {
            this.setContent(CONTENT_TYPE.SUCCESS);
            // this.props.triggerCallbacks.setStakeInfo();
        } else {
            this.setAction(ret.res, false);
            this.setContent(CONTENT_TYPE.ERROR);
        }
    };

	renderContent = (type) => {
		const {
			periods,
			currentBlock,
			tokenData,
			onCloseDialog,
			account,
			depositInfo,
			coins,
            stakables,
            balance,
		} = this.props;

		const { period, amount, action, isPending, isValid, error } = this.state;

        const address = stakables?.coin;
        const display_name = coins?.[address]?.display_name;
        let balance_in_wallet = 0;
        if (coins && stakables && balance) {
            const { min } = coins?.[stakables?.coin];
            balance_in_wallet = formatToCurrency(
                balance?.[`${stakables?.coin}_in_wallet`],
                min
            );
        }
        const balance_in_stake = depositInfo?.[0];

		const { symbol } = tokenData;
		switch (type) {
			case CONTENT_TYPE.LOADING:
				return <AllowanceLoader coins={coins} symbol={symbol} />;
            case CONTENT_TYPE.SELECT:
                return (
                    <Manage
                        onClickStake={()=>this.onClickStake(true)}
                        onClickUnstake={()=>this.onClickStake(false)}
                        onClickClaim={()=>this.onCLickClaim(false)}
                        onClickCompound={()=>this.onCLickClaim(true)}
                        onClose={onCloseDialog}
                    />
                );
			case CONTENT_TYPE.AMOUNT:
				return (
					<AmountContent
						tokenData={tokenData}
                        available={action == ACTION_TYPE.STAKE ? balance_in_wallet : balance_in_stake}
						onClose={onCloseDialog}
                        action={action}
						onBack={onCloseDialog}
						onNext={this.onProceed}
						amount={amount}
						setAmount={this.setAmount}
						isValid={isValid}
						error={error}
					/>
				);
			case CONTENT_TYPE.PERIOD:
				return (
					<PeriodContent
						tokenData={tokenData}
						periods={periods}
						onClose={onCloseDialog}
						onBack={() => this.setContent(CONTENT_TYPE.AMOUNT)}
						onReview={() => this.setContent(CONTENT_TYPE.REVIEW)}
						setPeriod={this.setPeriod}
						currentBlock={currentBlock}
						period={period}
						amount={amount}
						openReadMore={this.openReadMore}
					/>
				);
			case CONTENT_TYPE.REVIEW:
				return (
					<ReviewContent
						onClose={onCloseDialog}
						onCancel={() => this.setContent(CONTENT_TYPE.PERIOD)}
						onProceed={() =>
							this.approveAndStake(symbol)({ amount, period, account })
						}
						amount={amount}
					/>
				);
			case CONTENT_TYPE.WAITING:
				return (
					<WaitingContent
						isPending={isPending}
						action={action}
						amount={amount}
						symbol={symbol}
						onClose={onCloseDialog}
					/>
				);
			case CONTENT_TYPE.SUCCESS:
				return (
					<SuccessfulStakeContent
						// tokenData={tokenData}
						// account={account}
						action={action}
						// period={period}
						amount={amount}
                        display_name={display_name}
						// symbol={symbol}
						// currentBlock={currentBlock}
						onOkay={onCloseDialog}
					/>
				);
			case CONTENT_TYPE.ERROR:
				return <ErrorContent action={action} onOkay={onCloseDialog} />;
			default:
				return <div>No Content</div>;
		}
	};

	setContent = (type) => {
		this.setState({
			type,
		});
	};

	setAction = (action, isPending) => {
		this.setState({ action, isPending });
	};

	openReadMore = () => {
		const {
			contracts: {
				[STAKING_INDEX_COIN]: { whitepaper },
			},
		} = this.props;
		if (whitepaper) {
			open(whitepaper);
		}
	};

	render() {
		const { type } = this.state;

		return <div className="w-100">{this.renderContent(type)}</div>;
	}
}

const mapStateToProps = (store) => ({
	coins: store.app.coins,
    balance: store.user.balance,
	triggerCallbacks: store.app.triggerCallbacks,
});

const mapDispatchToProps = (dispatch) => ({
	// generateTableData: bindActionCreators(generateTableData, dispatch),
	// getAllUserStakes: bindActionCreators(getAllUserStakes, dispatch),
	// getPendingTransactions: bindActionCreators(getPendingTransactions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withConfig(wagmiUseStakeInfoHOC(StakeContent)));
