import React from 'react';
import { connect } from 'react-redux';
import { userStakesValueSelector } from 'containers/Stake/selector';
import { EditWrapper } from 'components';
import ConnectWrapper from './ConnectWrapper';
import STRINGS from 'config/localizedStrings';
import { formatToCurrency } from 'utils/currency';
import {
	BASE_CURRENCY,
	CURRENCY_PRICE_FORMAT,
	DEFAULT_COIN_DATA,
} from 'config/constants';
import { getPrices } from 'actions/assetActions';

const StakesAndEarnings = ({ stakables, depositInfo, coins, account }) => {
	const { min, display_name = '' } = coins?.[stakables?.coin];

    const [prices, setPrices] = React.useState([0, 0]);

    React.useEffect(()=>{
        getPrices({coins:{[stakables.coin]: null}}).then(ret=>{
            const price = ret[stakables.coin];
            setPrices([depositInfo?.[0]*price, depositInfo?.[1]*price]);
        });
    }, [stakables, depositInfo]);

	const totalStakesString = STRINGS.formatString(
		CURRENCY_PRICE_FORMAT,
		'$',
		formatToCurrency(prices?.[0], min)
	);

	const totalEarningsString = STRINGS.formatString(
		CURRENCY_PRICE_FORMAT,
		'$',
		formatToCurrency(prices?.[1], min)
	);

	return (
		<div
			className="secondary-text"
			style={{
				minWidth: 'max-content',
				paddingTop: '0.5rem',
				textAlign: 'right',
				marginLeft: '3rem',
			}}
		>
			<div>
				<EditWrapper stringId="STAKE.ESTIMATED_STAKED">
					{STRINGS['STAKE.ESTIMATED_STAKED']}
				</EditWrapper>
				<div>
					<ConnectWrapper account={account?.address}>{totalStakesString}</ConnectWrapper>
				</div>
				<div className="kit-divider" />
			</div>
			<div>
				<EditWrapper stringId="STAKE.ESTIMATED_EARNINGS">
					{STRINGS['STAKE.ESTIMATED_EARNINGS']}
				</EditWrapper>
				<div>
					<ConnectWrapper account={account?.address}>{totalEarningsString}</ConnectWrapper>
				</div>
				<div className="kit-divider" />
			</div>
		</div>
	);
};

const mapStateToProps = (store) => ({
	coins: store.app.coins,
	// ...userStakesValueSelector(store),
});

export default connect(mapStateToProps)(StakesAndEarnings);
