import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Filters from './Filters';
import TradeBlock from './TradeBlock';
import ActiveOrders from './ActiveOrders';
import { cancelOrder, cancelAllOrders, claim, claimAll } from 'actions/orderAction';
import { isLoggedIn } from 'utils/token';
import {
	ActionNotification,
	Dialog,
	IconTitle,
	Button,
	NotLoggedIn,
} from 'components';
import STRINGS from 'config/localizedStrings';
import withConfig from 'components/ConfigProvider/withConfig';
import { activeOrdersSelector } from '../utils';
import { EditWrapper } from 'components';
import { setActiveOrdersMarket } from 'actions/appActions';
import { message, Spin } from 'antd';
import { getChainConfig, getCurrentChain, getSubscribeMode } from 'utils/wagmiWrapper';

class OrdersWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cancelDelayData: [],
			showCancelAllModal: false,
            loadingOpenOrders: false,
		};
	}

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.loadingOpenOrders && 
			prevProps.activeOrdersMarket == this.props.activeOrdersMarket
		) {
            this.setState({loadingOpenOrders: false});
        }

		if (
			prevProps.activeOrdersMarket !== this.props.activeOrdersMarket
		) {
			this.props.triggerCallbacks.fetchOpenOrders(this.props.activeOrdersMarket);
		}
	}

	openConfirm = () => {
		this.setState({
			showCancelAllModal: true,
		});
	};

	cancelAllOrders = () => {
		const {
			activeOrdersMarket,
			settings,
			activeOrders,
		} = this.props;
		let cancelDelayData = [];
		activeOrders.forEach(({ id }) => {
			cancelDelayData = [...cancelDelayData, id];
		});
		this.setState({ cancelDelayData });
		setTimeout(() => {
			cancelAllOrders(activeOrdersMarket, settings).then(this.triggerCallbacks);
			this.onCloseDialog();
		}, 700);
	};

	handleCancelOrders = (id) => {
		const { settings } = this.props;
		this.setState({ cancelDelayData: this.state.cancelDelayData.concat(id) });
		setTimeout(() => {
			cancelOrder(id, settings, message).then(this.triggerCallbacks);
            this.onCloseDialog();
		}, 700);
	};

    handleClaim = (id) => {
		const { settings } = this.props;
		this.setState({ cancelDelayData: this.state.cancelDelayData.concat(id) });
		setTimeout(() => {
			claim(id, settings, message).then(this.triggerCallbacks);
            this.onCloseDialog();
		}, 700);
	};

    handleClaimAll = () => {
		const {
			activeOrdersMarket,
			settings,
			activeOrders,
		} = this.props;
		let cancelDelayData = [];
		activeOrders.forEach(({ id }) => {
			cancelDelayData = [...cancelDelayData, id];
		});
		this.setState({ cancelDelayData });
		setTimeout(() => {
			claimAll(activeOrdersMarket, settings).then(this.triggerCallbacks);
			this.onCloseDialog();
		}, 700);
	};

    triggerCallbacks = (ret) => {
        if (ret.ok) {
            message.success("Operation succeeded!");
            getSubscribeMode().then(mode=>{
                if (mode == 'trigger') {
                    getChainConfig().then(chain=>{
                        const callbacks = () => {
                            this.props.triggerCallbacks.fetchBalances();
                            this.props.triggerCallbacks.fetchOrderbook();
                            this.props.triggerCallbacks.fetchOpenOrders();
                        };
                        setTimeout(callbacks, chain.block_rate*1000);
                    });
                    
                }
            });
        } else {
            message.error(ret.res);
        }
    };

    setActiveMarket = (activeMarket) => {
        this.props.setActiveOrdersMarket(activeMarket);
        if (activeMarket !== this.props.activeOrdersMarket) {
            this.setState({loadingOpenOrders: true});
        }
    };

	onCloseDialog = () => {
		this.setState({ showCancelAllModal: false });
	};

	render() {
		const {
			activeOrders,
			pairs,
			icons: ICONS,
			tool,
			activeOrdersMarket,
			setActiveOrdersMarket,
			goToTransactionsHistory,
		} = this.props;
		const { 
            cancelDelayData, 
            showCancelAllModal,
            loadingOpenOrders
        } = this.state;

		return (
			<Fragment>
				<TradeBlock
					title={`${STRINGS['TOOLS.OPEN_ORDERS']} (${activeOrders.length})`}
					// action={
					// 	isLoggedIn() ? (
					// 		<ActionNotification
					// 			stringId="TRANSACTION_HISTORY.TITLE"
					// 			text={STRINGS['TRANSACTION_HISTORY.TITLE']}
					// 			iconId="ARROW_TRANSFER_HISTORY_ACTIVE"
					// 			iconPath={ICONS['ARROW_TRANSFER_HISTORY_ACTIVE']}
					// 			onClick={() => goToTransactionsHistory('order-history')}
					// 			status="information"
					// 			className="trade-wrapper-action"
					// 		/>
					// 	) : (
					// 		''
					// 	)
					// }
					stringId="TOOLS.OPEN_ORDERS"
					tool={tool}
					titleClassName="mb-4"
				>
					<NotLoggedIn
						placeholderKey="NOT_LOGGEDIN.TXT_1"
						hasBackground={false}
					>
						<div className="open-order-wrapper">
                            <Spin spinning={loadingOpenOrders}>
                                <Filters
                                    pair={activeOrdersMarket}
                                    onChange={this.setActiveMarket}
                                />
                                <ActiveOrders
                                    pageSize={activeOrders.length}
                                    activeOrdersMarket={activeOrdersMarket}
                                    pairs={pairs}
                                    cancelDelayData={cancelDelayData}
                                    orders={activeOrders}
                                    onCancel={this.handleCancelOrders}
                                    onCancelAll={this.openConfirm}
                                    onClaim={this.handleClaim}
                                    onClaimAll={this.handleClaimAll}
                                />
                            </Spin>
						</div>
					</NotLoggedIn>
				</TradeBlock>
				<Dialog
					isOpen={showCancelAllModal}
					label="token-modal"
					onCloseDialog={this.onCloseDialog}
					shouldCloseOnOverlayClick={true}
					showCloseText={false}
				>
					<div className="quote-review-wrapper">
						<IconTitle
							iconId="CANCEL_ORDERS"
							iconPath={ICONS['CANCEL_ORDERS']}
							stringId="CANCEL_ORDERS.HEADING"
							text={STRINGS['CANCEL_ORDERS.HEADING']}
							textType="title"
							underline={true}
							className="w-100"
						/>
						<div>
							<div>
								<EditWrapper
									stringId="CANCEL_ORDERS.SUB_HEADING"
									render={(string) => <div>{string}</div>}
								>
									{STRINGS['CANCEL_ORDERS.SUB_HEADING']}
								</EditWrapper>
							</div>
							<div className="mt-3">
								<EditWrapper stringId="CANCEL_ORDERS.INFO_1">
									<div>
										{STRINGS.formatString(
											STRINGS['CANCEL_ORDERS.INFO_1'],
											activeOrdersMarket.toUpperCase()
										)}
									</div>
								</EditWrapper>
							</div>
							<div className="mt-1 mb-5">
								<EditWrapper
									stringId="CANCEL_ORDERS.INFO_2"
									render={(string) => <div>{string}</div>}
								>
									{STRINGS['CANCEL_ORDERS.INFO_2']}
								</EditWrapper>
							</div>
							<div className="w-100 buttons-wrapper d-flex">
								<Button
									label={STRINGS['BACK_TEXT']}
									onClick={this.onCloseDialog}
								/>
								<div className="separator" />
								<Button
									label={STRINGS['CONFIRM_TEXT']}
									onClick={this.cancelAllOrders}
								/>
							</div>
						</div>
					</div>
				</Dialog>
			</Fragment>
		);
	}
}

OrdersWrapper.defaultProps = {
	activeOrders: [],
};

const mapStateToProps = (state) => {
    const activeOrders = activeOrdersSelector(state);
    return {
        activeOrders,
        settings: state.user.settings,
        activeOrdersMarket: state.app.activeOrdersMarket,
        triggerCallbacks: state.app.triggerCallbacks,
    };
};

const mapDispatchToProps = (dispatch) => ({
	setActiveOrdersMarket: bindActionCreators(setActiveOrdersMarket, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withConfig(OrdersWrapper));
