import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Filters from './Filters';
import TradeBlock from './TradeBlock';
import UserTrades from './UserTrades';
import { isLoggedIn } from 'utils/token';
import { ActionNotification, NotLoggedIn } from 'components';
import STRINGS from 'config/localizedStrings';
import withConfig from 'components/ConfigProvider/withConfig';
import { userTradesSelector } from '../utils';
import { setRecentTradesMarket } from 'actions/appActions';
import { Spin } from 'antd';

const RecentTradesWrapper = ({
	pair,
	pairData,
	userTrades,
	pairs,
	coins,
	discount,
	prices,
	icons: ICONS,
	goToTransactionsHistory,
	tool,
	recentTradesMarket,
	setRecentTradesMarket,
	fetched,
    triggerCallbacks,
}) => {
    const [loadingTrades, setLoadingTrades] = React.useState(false);

    React.useEffect(()=> {
        if (loadingTrades) {
            setLoadingTrades(false);
        }
    }, [userTrades]);

    const onChange = (curMarket) => {
        if (recentTradesMarket !== curMarket) {
            setRecentTradesMarket(curMarket);
            triggerCallbacks.fetchRecentTrades(curMarket);
            setLoadingTrades(true);
        }
    }

	return (
		<TradeBlock
			title={STRINGS['TOOLS.RECENT_TRADES']}
			// action={
			// 	isLoggedIn() ? (
			// 		<ActionNotification
			// 			stringId="TRANSACTION_HISTORY.TITLE"
			// 			text={STRINGS['TRANSACTION_HISTORY.TITLE']}
			// 			iconId="ARROW_TRANSFER_HISTORY_ACTIVE"
			// 			iconPath={ICONS['ARROW_TRANSFER_HISTORY_ACTIVE']}
			// 			onClick={() => goToTransactionsHistory('trades')}
			// 			status="information"
			// 			className="trade-wrapper-action"
			// 		/>
			// 	) : (
			// 		''
			// 	)
			// }
			stringId="TOOLS.RECENT_TRADES"
			tool={tool}
			titleClassName="mb-4"
		>
			<NotLoggedIn placeholderKey="NOT_LOGGEDIN.TXT_1" hasBackground={false}>
				<Fragment>
                    <Spin spinning={loadingTrades}>
                        <Filters pair={recentTradesMarket} onChange={onChange} />
                        <UserTrades
                            pageSize={userTrades.length}
                            trades={userTrades}
                            pair={pair}
                            pairData={pairData}
                            pairs={pairs}
                            coins={coins}
                            discount={discount}
                            prices={prices}
                            icons={ICONS}
                            isLoading={!fetched}
                        />
                    </Spin>
				</Fragment>
			</NotLoggedIn>
		</TradeBlock>
	);
};

RecentTradesWrapper.defaultProps = {
	userTrades: [],
};

const mapStateToProps = (state) => ({
	prices: state.asset.oraclePrices,
	userTrades: userTradesSelector(state),
	recentTradesMarket: state.app.recentTradesMarket,
	fetched: state.wallet.trades.fetched,
    triggerCallbacks: state.app.triggerCallbacks,
});

const mapDispatchToProps = (dispatch) => ({
	setRecentTradesMarket: bindActionCreators(setRecentTradesMarket, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withConfig(RecentTradesWrapper));
