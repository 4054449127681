import { setLogin } from 'actions/authAction';
import React from 'react';
import math from 'mathjs';
import { useAccount } from 'wagmi';
import { 
    bankReadContracts, 
    bitlyReadContract, 
    bitlyReadContracts, 
    calcBlockHeight, 
    calcRealTime, 
    EARNING_CLAIMED, 
    ERC20ReadContracts, 
    getPairLog, 
    getPrice, 
    pairReadContract, 
    pairReadContracts, 
    SWAPPED, 
    watchPairEvent,
    getCurrentChain,
    getSubscribeMode
} from 'utils/wagmiWrapper';
import store from 'store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPairs, setMarkets, setOrderLimits, setCurrencies, setTriggerCallbacks } from 'actions/appActions';
import { setBalance } from 'actions/userAction';
import withConfig from 'components/ConfigProvider/withConfig';
import { point2Price, toActualBalance } from 'utils/bitlyBignumber';
import { getPrices, setPricesAndAsset } from 'actions/assetActions';
import { setPairsData, setPrices, setTrades, getOrderbook, setOrderbooks } from 'actions/orderbookAction';
import { setUserOrders } from 'actions/orderAction';
import { addUserTrades } from 'actions/walletActions';
var _ = require('lodash');

const assetTemplate = {
    id: 7,
    fullname: "Monero",
    symbol: "xmr",
    active: true,
    verified: true,
    allow_deposit: true,
    allow_withdrawal: true,
    withdrawal_fee: 0,
    min: 0.00001,
    max: 10000000000,
    increment_unit: 0.00001,
    logo: "https://hollaex-resources.s3.ap-southeast-1.amazonaws.com/icons/xmr.svg",
    code: "xmr",
    is_public: true,
    meta: {},
    estimated_price: 0,
    description: null,
    type: "blockchain",
    decimal: 18,
    network: null,
    standard: null,
    issuer: "",
    contract: "",
    withdrawal_fees: null,
    display_name: null,
    deposit_fees: null,
    is_risky: false,
    created_at: "2020-02-03T10:19:10.857Z",
    updated_at: "2024-03-12T13:25:02.464Z",
    created_by: 1,
    owner_id: 1
};

const marketTemplate = {
    key: "contract_address",
    pair: {
        id: 1,
        name: "xht-usdt",
        pair_base: "xht",
        pair_2: "usdt",
        pair_base_address: "",
        pair_2_address: "",
        decimal: 18,
        pair_2_decimal: 18,
        min_size: 0.00001,
        max_size: 1000000000,
        min_price: 0.00001,
        max_price: 10000000,
        increment_size: 0.00001,
        increment_price: 0.00001,
        active: true,
        verified: true,
        code: "xht-usdt",
        is_public: true,
        circuit_breaker: false,
        created_at: "2020-02-03T10:19:10.846Z",
        updated_at: "2021-12-31T10:33:16.928Z",
        created_by: 80,
        pair_base_display: "XHT",
        pair_2_display: "USDT",
        display_name: "XHT/USDT",
    },
    symbol: "xht",
    pairTwo: {
        id: 6,
        fullname: "USD Tether",
        symbol: "usdt",
        active: true,
        verified: true,
        allow_deposit: true,
        allow_withdrawal: true,
        withdrawal_fee: 0,
        min: 0.00001,
        max: 1000000000,
        increment_unit: 0.00001,
        code: "usdt",
        is_public: true,
        description: "",
        type: "blockchain",
        standard: "erc-20",
        decimal: 18,
        withdrawal_fees: {},
        display_name: "USDT",
        deposit_fees: null,
        is_risky: false,
        created_at: "2020-02-03T10:19:10.857Z",
        updated_at: "2024-03-12T13:25:02.525Z",
        created_by: 80,
    },
    fullname: "HollaEx",
    fullMarketName: "HollaEx/USD Tether",
    increment_price: 0.00001,
    priceDifference: 0,
    tickerPercent: 0,
    priceDifferencePercent: "0%",
    display_name: "XHT/USDT",
    pair_base_display: "XHT",
    pair_2_display: "USDT",
    icon_url: "",
    price: 0,
};

const orderTemplate = {
	// topic: "order",
	// action: "partial",
	// user_id: 1,
	// data: [
	// 	{
			id: "7d3d9545-b7e6-4e7f-84a0-a39efa4cb173",
			side: "buy",
			symbol: "xht-usdt",
			type: "limit",
			size: 0.1,
			filled: 0,
            total: 0,
			price: 1,
			stop: null,
			status: "new",
			fee: 0,
			fee_coin: "xht",
			meta: {},
			fee_structure: {
				"maker": 0.0,
				"taker": 0.0
			},
			created_at: "2020-11-30T07:45:43.819Z",
			created_by: 1
	// 	},
	// ],
	// time: 1608022610
};

const tradeTemplate = {
    // count: 1,
    // data: [
    //     {
            side: "sell",
            symbol: "xht-usdt",
            size: 0.0,
            price: 0.0,
            timestamp: "2021-02-15T07:34:34.203Z",
            order_id: "string",
            fee: 0.0,
            fee_coin: "usdt"
    //     }
    // ]
};

const AppStateListener = (props) => {
    const account = useAccount();
    const [watchList, setWatchList] = React.useState([]);
    const [subscribeMode, setSubscribeMode] = React.useState("");
    const [markets, setMarketInfos] = React.useState([]);
    const [assetInfos, setAssetInfos] = React.useState({});
    const [lastOrderLimits, setLastOrderLimits] = React.useState({});
    const [lastPairs, setLastPairs] = React.useState({});
    const [lastBalance, setLastBalance] = React.useState({});
    const [lastOrder, setLastOrder] = React.useState([]);
    const [lastTrades, setLastTrades] = React.useState([]);
    const [lastTradesHistory, setLastTradesHistory] = React.useState({});

    const fetchMarkets = async () => {
        bitlyReadContract("tokens").then(async tokens=>{
            // fetch pairs info and fill states (markets & pairs)
            const infos = await bitlyReadContracts(tokens.map(t=>{ return {args: [t], functionName: "tokenInfo"} }));
            const tokenInfo = {};
            const assets = {};
            for (let index = 0; index < infos.length; index++) {
                const i = infos[index];
                tokenInfo[tokens[index]] = {
                    description: i[0],
                    url: i[1],
                    logo: i[2],
                    symbol: i[4],
                    name: i[3],
                    decimal: i[5],
                    id: index
                };
                let asset = JSON.parse(JSON.stringify(assetTemplate));
                asset.id = index;
                asset.fullname = i[3];
                asset.symbol = i[4];
                asset.decimal = i[5];
                asset.contract = tokens[index];
                asset.logo = URL.createObjectURL(new Blob([new Uint8Array(i[2].substring(2).match(/[\da-f]{2}/gi).map(function (h) {
                    return parseInt(h, 16)
                }))], {type: 'image/jpeg'}));
                asset.code = i[4];
                asset.description = i[0];
                asset.url = i[1];
                asset.display_name = i[4];
                assets[tokens[index]] = asset;
            }
            if (!_.isEqual(assets, assetInfos)) {
                store.dispatch(setCurrencies(assets));
                setAssetInfos(assets);
                store.dispatch(setPricesAndAsset({}, assets));
            }

            const pairInfos = [];
            infos.forEach(e => {
                pairInfos.push(...e[6]);
            });
            const mkts = [];
            const prs = {};
            const orderLimits = {};
            for (let index = 0; index < pairInfos.length; index++) {
                const tokenX = pairInfos[index].tokenX;
                const tokenY = pairInfos[index].tokenY;
                const pa = pairInfos[index].pair;
                let m = JSON.parse(JSON.stringify(marketTemplate));
                const infoX = tokenInfo[tokenX];
                const infoY = tokenInfo[tokenY];
                m.key = pa;
                m.price = await getPrice(pa);
                m.pair.id = infoX.id;
                m.pair.name = infoX.symbol+"-"+infoY.symbol;
                m.pair.pair_base = infoX.symbol;
                m.pair.pair_2 = infoY.symbol;
                m.pair.pair_base_address = tokenX;
                m.pair.pair_2_address = tokenY;
                m.pair.code = m.pair.name;
                m.pair.pair_base_display = infoX.symbol;
                m.pair.pair_2_display = infoY.symbol;
                m.pair.display_name = infoX.symbol+"/"+infoY.symbol;
                m.symbol = infoX.symbol;
                m.pair.decimal = infoX.decimal;
                m.pair.pair_2_decimal = infoY.decimal;
                m.pairTwo.decimal = infoY.decimal;
                m.pairTwo.id = infoY.id;
                m.pairTwo.fullname = infoY.name;
                m.pairTwo.symbol = infoY.symbol;
                m.pairTwo.code = infoY.symbol;
                m.pairTwo.description = infoY.description;
                m.pairTwo.display_name = infoY.symbol;
                m.fullname = infoX.name;
                m.fullMarketName = infoX.name+"/"+infoY.name;
                m.display_name = infoX.symbol+"/"+infoY.symbol;
                m.pair_base_display = infoX.symbol;
                m.pair_2_display = infoY.symbol;
                m.icon_url = URL.createObjectURL(new Blob([new Uint8Array(infoX.logo.substring(2).match(/[\da-f]{2}/gi).map(function (h) {
                    return parseInt(h, 16)
                }))], {type: 'image/jpeg'}));

                prs[m.key] = m.pair;
                mkts.push(m);

                orderLimits[m.key] = {
                    PRICE: {
                        MIN: m.pair.min_price,
                        MAX: m.pair.max_price,
                        STEP: m.pair.increment_price,
                    },
                    SIZE: {
                        MIN: m.pair.min_size,
                        MAX: m.pair.max_size,
                        STEP: m.pair.increment_price,
                    },
                };
            }
            if (!_.isEqual(lastOrderLimits, orderLimits)) {
                store.dispatch(setOrderLimits(orderLimits));
                setLastOrderLimits(orderLimits);
            }

            if (!_.isEqual(prs, lastPairs)) {
                store.dispatch(setPairs(prs));
                store.dispatch(setPairsData(prs));
                setLastPairs(prs);
            }

            if (mkts.toString() !== markets.toString()) {
                store.dispatch(setMarkets(mkts));
                setMarketInfos(mkts);
            }
        });
    };

    const fetchBalances = async () => {
        // fetch balances for all tokens listed
        if (account.address && assetInfos) {
            bitlyReadContract("tokens").then(async tokens=>{
                const balancesObj = {};

                const args = tokens.map(t=>{ return {address: t, args:[account.address], functionName: "balanceOf"} });
                const balances = await ERC20ReadContracts(args);

                const bankArgs = tokens.map(t=>{ return {args:[account.address, t], functionName: "balances"} });
                const balancesInBank = await bankReadContracts(bankArgs);

                const decimalArgs = tokens.map(t=>{ return {address: t, functionName: "decimals"} });
                const decimals = await ERC20ReadContracts(decimalArgs);

                for (let i = 0; i < balances.length; i++) {
                    const balance = balances[i];
                    const decimal = decimals[i];
                    const balanceInBank = balancesInBank[i];
                    const address = tokens[i];
                    balancesObj[`${address}_in_wallet`] = toActualBalance(balance, decimal).toNumber();
                    balancesObj[`${address}_in_bank`] = toActualBalance(balanceInBank, decimal).toNumber();
                }
                if (!_.isEqual(lastBalance, balancesObj)) {
                    store.dispatch(setBalance(balancesObj));
                    store.dispatch(setPrices(await getPrices({coins: assetInfos})));
                    store.dispatch(setPricesAndAsset(balancesObj, assetInfos));
                    setLastBalance(balancesObj);
                }
            });
        }
    };

    const setLoginStatus = async () => {
        if (account.address) {
            setLogin(true);
        } else {
            setLogin(false);
        }
    };

    const fetchOpenOrders = async (curMarket) => {
        // fetch open orders for user
        if (account.address && markets.length > 0 && props.pair?.length > 0) {
            curMarket = curMarket ? curMarket : props.pair;
            pairReadContract('limitOrders', [account.address], curMarket).then(async orderViews=>{
                const orderData = [];
                
                const market = markets.filter(m=>m.key==curMarket)?.[0];
                if (!market) {
                    return;
                }

                const pair = market.key;
                const tradeToken = market.pair.pair_base_address;
                const decimalX = market.pair.decimal;
                const decimalY = market.pairTwo.decimal;
                for (let j = 0; j < orderViews.length; j++) {
                    const order = orderViews[j];
                    let o = JSON.parse(JSON.stringify(orderTemplate));
                    o.id = pair + '-' + order.originToken + '-' + order.targetToken + '-' + order.point;
                    o.symbol = pair;
                    o.side = order.originToken == tradeToken ?  "sell" : "buy";
                    const earning = await pairReadContract("queryEarning", [order.targetToken, order.point], pair);
                    o.total = toActualBalance(Number(earning[2]), o.side=="buy"?decimalY:decimalX).toNumber();
                    o.size = toActualBalance(Number(earning[0]), o.side=="buy"?decimalY:decimalX).toNumber();
                    o.filled = toActualBalance(Number(earning[1]), o.side=="buy"?decimalX:decimalY).toNumber();
                    o.price = point2Price(order.point).toNumber();
                    orderData.push(o);
                }

                if (lastOrder.toString() !== orderData.toString()) {
                    store.dispatch(setUserOrders(orderData));
                    setLastOrder(orderData);
                }
            });
        }
    };

    const fetchRecentTrades = async (curMarket) => {
        // fetch recent trades for user
        curMarket = curMarket ? curMarket : props.pair;
        if (account.address && markets.length > 0 && props.pair?.length > 0) {
            const trades = [];

            const mkt = markets.filter(m=>m.key==curMarket)?.[0];
            if (!mkt) {
                return;
            }

            const logs = await getPairLog(mkt.key, EARNING_CLAIMED, {wallet: account.address}, await calcBlockHeight(-7*24*60*60));
            for (const log of logs) {
                let trade = JSON.parse(JSON.stringify(tradeTemplate));
                trade.symbol = mkt.key;
                trade.side = mkt.pair.pair_base_address == log.args.targetToken ? "buy" : "sell";
                const originToken = trade.side == "buy" ? mkt.pair.pair_base_address : mkt.pair.pair_2_address;
                trade.order_id = mkt.key + '-' + originToken + '-' + log.args.targetToken + '-' + Number(log.args.point);
                trade.price = point2Price(Number(log.args.point)).toNumber();
                trade.size = toActualBalance(Number(log.args.earning), trade.side == "buy" ? mkt.pair.decimal : mkt.pair.pair_2_decimal).toNumber();
                if (trade.side == "sell") {
                    trade.size = math.divide(trade.size, trade.price);
                }
                trade.timestamp = (await calcRealTime(log.blockNumber)).toISOString();
                trades.push(trade);
            }

            if (trades.toString() !== lastTrades.toString()) {
                store.dispatch(addUserTrades(trades));
                setLastTrades(trades);
            }
            // store.dispatch(setTrades(trades));
        }
    };

    const fetchTradesHistory = async () => {
        // fetch trades history for user
        if (assetInfos && markets.length > 0 && props.pair?.length > 0) {
            const mkt = markets.filter(m=>m.key==props.pair)?.[0];
            if (!mkt) {
                return;
            }

            const trades = {
                topic: "trade",
                action: "partial",
                symbol: mkt.key,
                time: parseInt((new Date()).getTime()/1000),
                [mkt.key]: [],
            };
            const logs = await getPairLog(mkt.key, SWAPPED, undefined, await calcBlockHeight(-1*24*60*60));
            for (const log of logs) {
                let trade = {};
                trade.side = mkt.pair.pair_base_address == log.args.originToken ? "sell" : "buy";
                trade.price = point2Price(log.args.point).toNumber();
                trade.size = toActualBalance(log.args.amount, trade.side == "buy" ? mkt.pair.decimal : mkt.pair.pair_2_decimal).toNumber();
                trade.timestamp = (await calcRealTime(log.blockNumber)).toISOString();
                trades[mkt.key].push(trade);
            }
            if (!_.isEqual(lastTradesHistory, trades)) {
                store.dispatch(setTrades(trades));
                setLastTradesHistory(trades);
            }
        }
    };

    const fetchOrderbook = async () => {
        if (props.pair?.length > 0) {
            getOrderbook(props.pair, undefined, props.orderbookWidth).then(ob=>{
                props.setOrderbooks(ob);
            });
        }
    };

    React.useEffect(()=>{
        getSubscribeMode().then(mode=>{
            setSubscribeMode(mode);
        });
        fetchMarkets();
    }, []);

    // watch mode
    React.useEffect(()=>{
        if (account.address && markets.length > 0 && subscribeMode == 'watch') {
            watchList.forEach(unwatch=>unwatch ?? unwatch());
            const watched = [];
            watched.push(watchPairEvent(props.pair, "LimitOrderPlaced", ()=>{
                fetchOrderbook();
            }));
            watched.push(watchPairEvent(props.pair, "MarketOrderCompleted", ()=>{
                fetchOrderbook();
                fetchOpenOrders();
                fetchBalances();
            }));
            watched.push(watchPairEvent(props.pair, "LimitOrderCanceled", ()=>{
                fetchOrderbook();
                fetchBalances();
            }));
            watched.push(watchPairEvent(props.pair, "SWAPPED", ()=>{
                fetchTradesHistory();
            }));
            watched.push(watchPairEvent(props.pair, "LimitOrderPlaced", ()=>{
                fetchOpenOrders();
                fetchBalances();
            }, {
                wallet: account.address
            }));
            watched.push(watchPairEvent(props.pair, "LimitOrderCanceled", ()=>{
                fetchOpenOrders();
            }, {
                wallet: account.address
            }));
            watched.push(watchPairEvent(props.pair, "EarningClaimed", ()=>{
                fetchOpenOrders();
                fetchRecentTrades();
                fetchBalances();
            }, {
                wallet: account.address
            }));
            setWatchList(watched);
        }
    }, [account.address, JSON.stringify(markets), props.pair, props.orderbookWidth, subscribeMode]);

    // timer mode
    React.useEffect(()=>{
        if (account.address && markets.length > 0 && subscribeMode == 'timer') {
            setInterval(()=>{
                fetchOrderbook();
                setTimeout(fetchOpenOrders, 5000);
                setTimeout(fetchBalances, 10000);
                setTimeout(fetchTradesHistory, 15000);
                setTimeout(fetchRecentTrades, 20000);
            }, 25000);
        }
    }, [subscribeMode, account.address, JSON.stringify(markets)]);

    React.useEffect(()=>{
        setLoginStatus();
    }, [account.address]);

    React.useEffect(()=>{
        fetchBalances();
    }, [account.address, JSON.stringify(assetInfos)]);

    React.useEffect(()=>{
        fetchOpenOrders();
    }, [account.address, JSON.stringify(markets), props.pair]);

    React.useEffect(()=>{
        fetchRecentTrades();
    }, [account.address, JSON.stringify(markets), props.pair]);

    React.useEffect(()=>{
        fetchTradesHistory();
    }, [JSON.stringify(assetInfos), JSON.stringify(markets), props.pair]);

    React.useEffect(()=>{
        store.dispatch(setTriggerCallbacks({
            fetchMarkets,
            fetchBalances,
            fetchOpenOrders,
            fetchRecentTrades,
            fetchTradesHistory,
            fetchOrderbook,
        }));
    }, [JSON.stringify(assetInfos), JSON.stringify(markets), props.pair, account.address, props.orderbookWidth]);


	return (<>{props.children}</>);
};


const mapStateToProps = (state) => {
    const pair = state.app.pair;
    const trigger = state.app.trigger;
    const orderbookWidth = state.orderbook.width;
	return {
        trigger,
        pair,
        orderbookWidth,
	};
};

const mapDispatchToProps = (dispatch) => ({
    setOrderbooks: bindActionCreators(setOrderbooks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withConfig(AppStateListener));