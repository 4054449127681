import React, { Fragment, useState } from 'react';
import {
	Input,
	InputNumber,
	Button,
	Form,
	Checkbox,
	message,
	Modal,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import _toUpper from 'lodash/toUpper';
import _get from 'lodash/get';

import { STATIC_ICONS } from 'config/icons';
import Coins from '../Coins';
import ColorPicker from '../ColorPicker';
import {
	getCoinInfo,
	storeAsset,
	uploadCoinLogo,
} from '../action';
import { bitlyWriteContract } from '../../../utils/wagmiWrapper';
import { fromBytes } from 'viem';

const CONTACT_DESCRIPTION_LINK =
	'https://metamask.zendesk.com/hc/en-us/articles/360015488811-What-is-a-Token-Contract-Address-';

const { Search, TextArea } = Input;

// const radioStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     height: '30px',
//     lineHeight: '1.2',
//     padding: '24px 0',
//     margin: 0,
//     paddingLeft: '1px',
//     whiteSpace: 'normal',
//     letterSpacing: '-0.15px',
// };

const AssetConfig = (props) => {
	const [isSupply, setIsApply] = useState(false);
	const [showPresetAlert, setPresetAlert] = useState(false);
	const [submitting, setSubmitting] = useState(false);
    const [coinFormData, setCoinFormData] = useState({});
	const [form] = Form.useForm();
	const {
		handleNext,
        handleConfirmation,
	} = props;

	const handleSubmit = async (values) => {
        if (!coinFormData.logo || !coinFormData.address || !coinFormData.link || !coinFormData.description) {
            handleConfirmation(false, "Please fill the form first");
            return;
        }
        const logoArray = Array.prototype.slice.call(new Uint8Array(await coinFormData.logo.arrayBuffer()));
        if (logoArray.length > 3 * 1024 /*3KB*/) {
            handleConfirmation(false, "Logo size should be less than 3KB");
            return;
        }
        const { ok, res} = await bitlyWriteContract(
            "listToken", 
            [
                coinFormData.address, 
                coinFormData.description, 
                coinFormData.link, 
                fromBytes(logoArray, 'hex')
            ]
        );
        if (ok) {
            handleConfirmation(true, "Done! Block mining might take longer time!");
            handleNext();
        } else {
            handleConfirmation(false, res);
        }
	};

	const handlePresetAdd = async () => {
		const body = {
			...props.coinFormData,
		};
		props.handlePresetConfirmation(body.symbol);
	};

	const updateAsset = async () => {
		const { logoFile, iconName, ...resetFormData } = props.coinFormData;
		const body = {
			...resetFormData,
		};
		if (!body.estimated_price) {
			body.estimated_price = 1;
		}
		if (!body.standard) {
			body.standard = '';
		}
		if (!body.network) {
			body.network = '';
		}
		if (!body.logo) {
			body.logo = '';
		}
		if (body.type === 'blockchain' && body.network === 'eth' && !isSupply) {
			delete body.meta.supply;
		}
		if (body.decimals) {
			body.decimals = parseInt(body.decimals, 10);
		}
		setSubmitting(true);
		try {
			if (logoFile) {
				let formData = new FormData();
				formData.append('name', iconName);
				formData.append('file_name', iconName);
				formData.append('file', logoFile);
				const logo = await uploadCoinLogo(formData);
				body.logo = _get(logo, 'data.path', '');
				props.handleBulkUpdate({
					logo: body.logo,
					logoFile: null,
					iconName: '',
				});
			}
			const res = await storeAsset(body);
			if (props.getCoins) {
				await props.getCoins();
			}
			if (props.handleRefreshCoin) {
				await props.handleRefreshCoin(body);
			}
			if (res) {
				handleNext();
			}
			setSubmitting(false);
		} catch (error) {
			if (error && error.data) {
				message.error(error.data.message);
			}
			setSubmitting(false);
		}
	};

	// const handleChangeSupply = (e) => {
	//     if (e.target.value === 'limited') {
	//         props.handleMetaChange(0, 'supply');
	//     } else {
	//         props.handleMetaChange(e.target.value, 'supply');
	//     }
	// };

	const handleSearch = async (address) => {
		const { handleBulkUpdate, handleMetaChange, coinFormData } = props;
		const params = {
			address,
			network: coinFormData.network,
		};
		try {
			const res = await getCoinInfo(params);
			if (res) {
				const data = {
					...res,
					fullname: res.name,
					symbol: res.symbol.toLowerCase(),
				};
				if (res && res.supply) {
					setIsApply(true);
				} else {
					setIsApply(false);
				}
				handleBulkUpdate(data);
				handleMetaChange(address, 'contract');
			}
		} catch (error) {
			if (error.data && error.data.message) {
				message.error(error.data.message);
			} else {
				message.error(error.message);
			}
		}
	};

	const checkCoin = (rule, value, callback) => {
		let coinData = props.coins
			.filter((coin) => coin.active)
			.map((coin) => {
				return coin.symbol;
			});
		if (coinData.includes(value)) {
			callback('This symbol already exists for this asset');
		} else {
			callback();
		}
	};

	const checkLower = (rule, value, callback) => {
		if (/[A-Z]/g.test(value)) {
			callback('Asset symbol must be in Lower case');
		} else {
			callback();
		}
	};

	const handleCloseAlert = () => {
		setPresetAlert(false);
	};

    const handleChange = (e, isFile) => {
		const newCoinFormData = {
			...coinFormData,
			[e.target.name]: isFile ? e.target.files[0] : e.target.value,
		};
		setCoinFormData(newCoinFormData);
	};

	return (
		<Fragment>
			<div className="title">Add a new token</div>
			<Form
				form={form}
				initialValues={coinFormData}
				name="AssetConfigForm"
				onFinish={handleSubmit}
			>
                <div className="section-wrapper">
					<div className="sub-title">Token Contract Address</div>
					<div className="d-flex align-items-center">
						{/* <div className="preview-icon"></div> */}
						<div className="md-field-wrap">
                            <Input
                                name="address"
                                onChange={handleChange}
                                value={coinFormData.address}
                            />
						</div>
					</div>
				</div>
				<div className="section-wrapper">
					{/* <div className="sub-title">About</div> */}
					<div className="about-wrapper">
						<div className="md-field-wrap">
							<div className="sub-title">Description</div>
							<div>Write a short description of this asset</div>
							<TextArea
								placeholder="Input a message"
								name="description"
								rows={3}
								onChange={handleChange}
							/>
						</div>
					</div>
				</div>
                <div className="section-wrapper">
					{/* <div className="sub-title">About</div> */}
					<div className="about-wrapper">
						<div className="md-field-wrap">
							<div className="sub-title">Official Link</div>
							<div>Your official website relative to the asset</div>
							<TextArea
								placeholder="https://bitly.exchange"
								name="link"
								rows={3}
								onChange={handleChange}
							/>
						</div>
					</div>
				</div>
				<div className="section-wrapper">
					<div className="sub-title">Icon</div>
					<div className="d-flex align-items-center">
						<div className="md-field-wrap">
							{coinFormData.logo ? (
								<img
									src={
										URL.createObjectURL(coinFormData.logo)
									}
									alt="coin"
									className="preview-icon"
								/>
							) : (
								<div className="icon-upload">
									<div className="file-container">
										<label>
											<UploadOutlined
												style={{
													fontSize: '94px',
													color: '#808080',
													marginRight: '8px',
												}}
											/>
											<input
												type="file"
												onChange={e => handleChange(e, true)}
												name="logo"
											/>
										</label>
									</div>
								</div>
							)}
						</div>
						<div className="md-field-wrap">
							<div>
								<span className="sub-title">Upload asset icon</span>{' '}
								<span>
									(this icon will be shown as LOGO of your asset):
								</span>
							</div>
							<div className="file-container">
								<label>
									<span>+ Upload ICON size limit 3KB</span>
									<input
										type="file"
										onChange={e => handleChange(e, true)}
										name="logo"
										accept="image/jpg, image/jpeg, image/png" /* image/svg+xml */
									/>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="btn-wrapper">
					{/* <Button type="primary" className="green-btn" onClick={handleBack}>
						Back
					</Button>
					<div className="separator"></div> */}
					<Button
						type="primary"
						className="green-btn"
						htmlType="submit"
						disabled={submitting}
					>
						Submit
					</Button>
				</div>
			</Form>
			<Modal
				visible={showPresetAlert}
				footer={null}
				onCancel={handleCloseAlert}
			>
				<div className="create-asset-container">
					{`${_toUpper(
						coinFormData.symbol
					)} is already created by some one. Do you really want to add it on exchange?`}
					<div className="btn-wrapper">
						<Button
							type="primary"
							className="green-btn"
							onClick={handleCloseAlert}
						>
							Cancel
						</Button>
						<div className="separator"></div>
						<Button
							type="primary"
							className="green-btn"
							onClick={handlePresetAdd}
						>
							Okay
						</Button>
					</div>
				</div>
			</Modal>
		</Fragment>
	);
};

export default AssetConfig;
