import axios from 'axios';
import { API_URL } from 'config/constants';
import math from 'mathjs';
import moment from 'moment';
import { point2Price, toActualBalance, toPlainBalance } from 'utils/bitlyBignumber';
import { calcBlockHeight, calcRealTime, getPairLog, getPrice, pairReadContract, SWAPPED } from 'utils/wagmiWrapper';
import { getDecimals } from '../utils/utils';

export const getChartConfig = () => {
	// return axios({
	// 	url: '/udf/config',
	// 	method: 'GET',
	// }).then((res) => {
	// 	return res.data;
	// });
	const config = {
		supported_resolutions: ['60', '240', '1D'],
		supports_group_request: false,
		supports_marks: false,
		supports_search: true,
		supports_timescale_marks: false,
	};
	return new Promise((resolve) => {
		resolve(config);
	});
};

export const getChartSymbol = (symbol, tickSize, api_name = '') => {
	let pricescale = math.round(1 / tickSize);
	if (/[^0-1]/g.test(pricescale)) {
		let count = getDecimals(tickSize);
		pricescale = math.pow(10, count);
	}
	if (pricescale === 0) pricescale = 1;
	// return axios({
	// 	url: `/udf/symbols?symbol=${symbol}`,
	// 	method: 'GET'
	// });
	return new Promise((resolve) => {
		resolve({
			name: `${symbol.toUpperCase()}`,
			ticker: symbol,
			exchange: api_name.toUpperCase(),
			has_intraday: true,
			has_daily: true,
			has_weekly_and_monthly: true,
			session: '24x7',
			regular_session: '24x7',
			pricescale,
			minmov: 1,
			volume_precision: 4,
			has_empty_bars: true,
		});
	});
};

export const getChartHistory = (symbol, pairs, resolution, from, to) => {
	// return axios({
	// 	url: `/chart?symbol=${symbol}&resolution=${resolution}&from=${from}&to=${to}`,
	// 	method: 'GET',
	// });
    const relativeFrom = from - (new Date()).getTime()/1000;
    let relativeto = to - (new Date()).getTime()/1000;
    if (relativeto > 0) {
        relativeto = 0;
    }
    const resolutionGroup = ((originRes) => {
        switch (originRes) {
            case "60":
                return ["H", 1];
            case "240":
                return ["H", 4];
            default:
                return ["D", 1];
        }
    })(resolution);
    return getChart(symbol, pairs, resolutionGroup[0], resolutionGroup[1], relativeFrom, relativeto);
};

export const getSparklines = async (pairs, pairsInfo) => {
	// const from = moment().subtract('1', 'days').format('X');
	// const to = moment().format('X');
	// const { data = {} } = await axios({
	// 	url: `/charts?resolution=H&from=${from}&to=${to}`,
	// 	method: 'GET',
	// });
    const data = {};
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i];
        data[pair] = await getChart(pair, pairsInfo, 'H', 4, -1*24*60*60-60, 0);
    }

	const chartData = {};
	Object.entries(data).forEach(([pairKey, pairData = []]) => {
		chartData[pairKey] = {};
		chartData[pairKey]['close'] = pairData.map(({ close }) => close);
		chartData[pairKey]['open'] = (pairData[0] && pairData[0]['open']) || 0;
	});

	return chartData;
};

const getChart = async (pair, pairs, resolution, step=1, from, to) => {
    const decimal = pairs[pair].decimal;
    const data = [];

    const fromBlock = await calcBlockHeight(from);
    const toBlock = await calcBlockHeight(to);

    const bars = await fetch(API_URL+`/api/getChart?pair=${pair}&from=${fromBlock}&to=${toBlock}&resolution=${resolution}&step=${step}`);
    if (bars.ok) {
        const ret = await bars.json();
        if (ret.err_code == 'SUCCESS') {
            return ret.data;
        }
    }
    
    const fromTime = await calcRealTime(fromBlock);
    const toTime = await calcRealTime(toBlock);
    const lastTime = fromTime;

    if (toBlock <= 1) {
        return [];
    }

    let beginPrice = await getPrice(pair, fromBlock);
    let endPrice = await getPrice(pair, toBlock);
    if (endPrice == 0) {
        return [];
    }
    const logs = await getPairLog(pair, SWAPPED, undefined, fromBlock, toBlock);

    const increase = (curTime) => {
        switch (resolution) {
            case "S":
                curTime.setSeconds(curTime.getSeconds()+step);
                break;
            case "M":
                curTime.setMinutes(curTime.getMinutes()+step);
                break;
            case "H":
                curTime.setHours(curTime.getHours()+step);
                break;
            default:
                curTime.setDate(curTime.getDate()+step);
        }
    };

    let bar = {
        open: beginPrice,
        close: beginPrice,
        high: beginPrice,
        low: beginPrice,
        volume: 0,
        symbol: pair,
        time: lastTime.toISOString()
    };

    for (let logBeginIndex = 0; lastTime.getTime() < toTime.getTime(); increase(lastTime)) {
        const breakTime = new Date(lastTime.getTime());
        increase(breakTime);

        for (; logBeginIndex < logs.length; logBeginIndex ++) {
            const log = logs[logBeginIndex];
            const eventTime = await calcRealTime(log.blockNumber.toString());
            if (eventTime.getTime() > breakTime.getTime()) {
                break;
            }
            bar.volume += toActualBalance(log.args.amount.toString(), decimal).toNumber();
            const curPrice = point2Price(log.args.point).toNumber();
            bar.close = curPrice;
            bar.high = curPrice > bar.high ? curPrice : bar.high;
            bar.low = curPrice < bar.low ? curPrice : bar.low;
        }

        bar.time = breakTime.toISOString();
        if (bar.close !== 0 || bar.open !== 0) {
            data.push(bar);
        }
        beginPrice = bar.close;

        bar = {
            open: beginPrice,
            close: beginPrice,
            high: beginPrice,
            low: beginPrice,
            volume: 0,
            symbol: pair,
            time: lastTime.toISOString()
        };
    }
    return data;
};

export const getMiniCharts = async (pair, pairs={}) => {
    if (!pair || Object.keys(pairs).length == 0) {
        return {};
    }

	const bars = await getChart(pair, pairs, 'H', 1, -1*24*60*60, 0);
    const data = {};

    const symbol = pairs[pair].pair_base_display;
    const quote = pairs[pair].pair_2_display;

    const prices = bars.map(bar=>{
        return {
            time: bar.time,
            price: bar.close,
            symbol,
            quote
        };
    });
    data[symbol] = prices;

	const result = {};
	
	Object.keys(data).forEach((keyVal) => {
		data[keyVal].forEach(({ price, quote, symbol, time }) => {
			let symbolKey = pair; 

			if(!result[symbolKey]) {
				result[symbolKey] = {
					price:[],
					time:[]
				};
			}

			result[symbolKey].price.push(price);
			result[symbolKey].time.push(time);
		});
	});

	return result;
}
			