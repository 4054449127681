import { Button, Drawer } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import STRINGS from 'config/localizedStrings';
import {
    WalletOutlined,
    MenuUnfoldOutlined,
    HomeOutlined,
    LinkOutlined,
    DollarCircleOutlined
} from '@ant-design/icons';
import { MAINNET_URL, NETWORK, TESTNET_URL } from 'config/constants';

const Navigator = () => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const renderTitle = () => {
        const url = NETWORK == 'mainnet' ? TESTNET_URL : MAINNET_URL;
        const content = NETWORK == 'mainnet' ? 'Testnet' : 'Mainnet';
        return (
            <div>
                {STRINGS['MENU_TITLE']}
                <Button style={{marginLeft: '0.5rem'}} href={url} > <LinkOutlined /> {content} </Button>
            </div>
        );
    }

    return (
        <>
            <MenuUnfoldOutlined
                style={{
                    padding: '0 24px',
                    fontSize: '18px',
                    lineHeight: '64px',
                    cursor: 'pointer',
                    transition: 'color 0.3s'
                }}
                onClick={showDrawer}
            />

            <Drawer title={renderTitle()} placement="left" onClose={onClose} visible={open} >
                <p>
                    <Link to="/">
                        <span className="trade_tabs-link">
                            <HomeOutlined style={{ marginRight: '1rem' }} />
                        </span>
                        <span className="trade_tabs-link">
                            {STRINGS['HOME_TITLE']}
                        </span>
                    </Link>
                </p>

                <p>
                    <Link to="/stake">
                        <span className="trade_tabs-link">
                            <DollarCircleOutlined style={{ marginRight: '1rem' }} />
                        </span>
                        <span className="trade_tabs-link">
                            {STRINGS['STAKE_TITLE']}
                        </span>
                    </Link>
                </p>

                {NETWORK == "testnet" && <p>
                    <Link to="/wallet">
                        <span className="trade_tabs-link">
                            <WalletOutlined style={{ marginRight: '1rem' }} />
                        </span>
                        <span className="trade_tabs-link">
                            {STRINGS['WALLET_TITLE']}
                        </span>
                    </Link>
                </p>}
            </Drawer>
        </>
    );
};
export default Navigator;