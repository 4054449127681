import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import mathjs from 'mathjs';
import classnames from 'classnames';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Button as AntBtn } from 'antd';
import {
	connectWallet,
	loadBlockchainData,
	getCurrentBlock,
	generateTableData,
	getAllPeriods,
	getAllUserStakes,
	getPendingTransactions,
	getAllPenalties,
	getAllPots,
} from 'actions/stakingActions';
import { setNotification, NOTIFICATIONS, requestConstant } from 'actions/appActions';
import { Link } from 'react-router-dom';
import { web3 } from 'config/contracts';
import STRINGS from 'config/localizedStrings';
import { DEFAULT_COIN_DATA } from 'config/constants';
import { STAKING_INDEX_COIN } from 'config/contracts';
import {
	IconTitle,
	HeaderSection,
	EditWrapper,
	Button,
	ProgressBar,
	Help,
	Coin,
    Loader,
} from 'components';
import withConfig from 'components/ConfigProvider/withConfig';
import { open } from 'helpers/link';

import {
	userActiveStakesSelector,
	pendingTransactionsSelector,
	networksMismatchSelector,
} from './selector';
import { getEstimatedRemainingTime, calculateEsimatedDate } from 'utils/eth';
import { formatToCurrency } from 'utils/currency';
import StakesAndEarnings from './components/StakesAndEarnings';
import Variable from './components/Variable';
import './CeFiStake.scss';
import { withRouter } from 'utils/router';
import { isMobile } from 'react-device-detect';

class Stake extends Component {
	constructor(prop) {
		super(prop);
		this.state = {
			activeTab: '1',
			selectedStaking: 'defi',
			readBeforeAction: false,
			stakeAmount: false,
			duration: false,
			stakeDetails: false,
			confirmStake: false,
			confirmation: false
		};
	}

	UNSAFE_componentWillMount() {
	}

	componentDidUpdate(prevProps) {
	}

	startStakingProcess = (tokenData) => {
		const { symbol } = tokenData;
		const { coins, setNotification } = this.props;
		const { fullname, display_name, icon_id } = coins[symbol];
		setNotification(NOTIFICATIONS.STAKE, {
			tokenData: { ...tokenData, fullname, display_name, icon_id },
		});
	};

	// startEarlyUnstakingProcess = (stakeData) => {
	// 	const { setNotification } = this.props;
	// 	setNotification(NOTIFICATIONS.EARLY_UNSTAKE, { stakeData });
	// };

	// startUnstakingProcess = (stakeData) => {
	// 	const { setNotification } = this.props;
	// 	setNotification(NOTIFICATIONS.UNSTAKE, { stakeData });
	// };

	moveXHT = () => {
		const { setNotification } = this.props;
		setNotification(NOTIFICATIONS.MOVE_XHT, {});
	};

	renderBalanceInBank = () => {
		const { balance, coins, stakables, account } = this.props;
        let content;
        if (balance && coins && stakables) {
            const { min } = coins[stakables.coin];
            content = account?.address ? formatToCurrency(
                balance?.[`${stakables.coin}_in_bank`],
                min
            ) : STRINGS['NOT_LOGGEDIN.SEE_MORE'];
        }

		return <span className="secondary-text">{content}</span>;
	};

    renderBalanceInWallet = () => {
		const { balance, coins, stakables, account } = this.props;
        let content;
        if (balance && coins && stakables) {
            const { min } = coins[stakables.coin];
            content = account?.address ? formatToCurrency(
                balance?.[`${stakables.coin}_in_wallet`],
                min
            ) : STRINGS['NOT_LOGGEDIN.SEE_MORE'];
        }

		return <span className="secondary-text">{content}</span>;
	};

	goToDetails = (symbol) => {
		const { router } = this.props;
		router.navigate(`/assets/coin/${symbol}`);
	};

	render() {
		const {
			icons: ICONS,
			coins,
			account,
			stakables,
			networksMismatch,
            apr,
            depositInfo
		} = this.props;

        if (!coins || !stakables) {
            return (<Loader />);
        }

        const index_display_name = coins[stakables.coin]?.display_name;
        const min = coins[stakables.coin]?.min;

		return (
			<div className="presentation_container apply_rtl wallet-wrapper">
				<div className="d-flex align-end justify-content-between">
					{this.state.selectedStaking === 'defi' && (
						<IconTitle
							stringId="STAKE.TITLE"
							text={STRINGS['STAKE.TITLE']}
							iconPath={ICONS['TAB_STAKE']}
							iconId="TAB_STAKE"
							textType="title"
						/>
					)}

					{/* {this.state.selectedStaking === 'defi' && <Account />} */}
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{this.state.selectedStaking === 'cefi' && (
						<div style={{ width: 50, height: 40, marginBottom: 10 }}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="59.482"
								height="50.688"
								viewBox="0 0 59.482 50.688"
								class="w-100 h-100"
							>
								<g transform="translate(0 0)">
									<path
										class="fill_secondary-color stroke_none"
										d="M93.737,82.874l8.584-10.938-8.581-10.91L85.156,71.964Z"
										transform="translate(-63.997 -45.863)"
										fill="#fff"
									/>
									<path
										class="fill_secondary-color stroke_none"
										d="M84.424,0l8.763,11.141L101.931,0Z"
										transform="translate(-63.448 0)"
										fill="#fff"
									/>
									<path
										class="fill_secondary-color stroke_none"
										d="M126.056,16.8l8.613,10.95h19.548l-18.787-22.9Z"
										transform="translate(-94.735 -3.649)"
										fill="#fff"
									/>
									<path
										class="fill_secondary-color stroke_none"
										d="M0,27.794H19.569l8.592-10.949L18.776,4.913Z"
										transform="translate(0 -3.692)"
										fill="#fff"
									/>
									<path
										class="fill_secondary-color stroke_none"
										d="M135.814,107l-9.758,12.435,8.788,11.173L154.217,107Z"
										transform="translate(-94.735 -80.415)"
										fill="#fff"
									/>
									<path
										class="fill_secondary-color stroke_none"
										d="M89.21,174.8h15.17l-7.594-9.655Z"
										transform="translate(-67.044 -124.11)"
										fill="#fff"
									/>
									<path
										class="fill_secondary-color stroke_none"
										d="M0,107l19.383,23.622,8.777-11.185L18.38,107Z"
										transform="translate(-0.001 -80.415)"
										fill="#fff"
									/>
								</g>
							</svg>
						</div>
					)}

					<h2>Stake</h2>
					<div>Earn rewards for staking your digital assets</div>
				</div>

				{this.state.selectedStaking === 'defi' && (
					<>
						<div
							className={classnames('wallet-container', 'no-border', {
								'area-disabled': networksMismatch,
							})}
						>
							<div className="wallet-assets_block">
								<div className="d-flex justify-content-between align-start">
									<div>
										<HeaderSection
											stringId="STAKE.DEFI_TITLE"
											title={STRINGS['STAKE.DEFI_TITLE']}
										>
											<div className="header-content">
												<div>
													<EditWrapper stringId="STAKE.DEFI_TEXT">
														{STRINGS['STAKE.DEFI_TEXT']}
													</EditWrapper>
												</div>
											</div>
										</HeaderSection>
										<div className="secondary-text">
											{STRINGS.formatString(
												STRINGS['STAKE.ON_EXCHANGE_XHT'],
												index_display_name,
                                                this.renderBalanceInBank(),
												(
													<span onClick={this.moveXHT}>
														(
														{
															<span className="blue-link pointer">
																{STRINGS.formatString(
																	STRINGS['STAKE.MOVE_XHT'],
																	index_display_name
																)}
															</span>
														}
														)
													</span>
												)
											)}
										</div>
									</div>
									<StakesAndEarnings stakables={stakables} depositInfo={depositInfo} account={account} />
								</div>
								<table className="wallet-assets_block-table">
									<thead>
										<tr className="table-bottom-border">
											<th />
											<th>
												<EditWrapper stringId="STAKE_TABLE.CURRENCY">
													{STRINGS['STAKE_TABLE.CURRENCY']}
												</EditWrapper>
											</th>
											<th>
												<EditWrapper stringId="STAKE_TABLE.AVAILABLE">
													{STRINGS['STAKE_TABLE.AVAILABLE']}
												</EditWrapper>
											</th>
											<th>
												<EditWrapper stringId="STAKE_TABLE.TOTAL">
													{STRINGS['STAKE_TABLE.TOTAL']}
												</EditWrapper>
											</th>
											<th>
												<EditWrapper stringId="STAKE_TABLE.REWARD_RATE">
													{STRINGS['STAKE_TABLE.REWARD_RATE']}
												</EditWrapper>
											</th>
											<th>
												<EditWrapper stringId="STAKE_TABLE.EARNING">
													{STRINGS['STAKE_TABLE.EARNINGS']}
												</EditWrapper>
											</th>
											<th>
												<EditWrapper stringId="STAKE_LIST.MANAGE">
													{STRINGS['STAKE_LIST.MANAGE']}
												</EditWrapper>
											</th>
										</tr>
									</thead>
									<tbody>
										{[
                                            {symbol: stakables.coin},
                                        ].map((tokenData, index) => {
											const { symbol, available } = tokenData;
											const { fullname, display_name, logo } = coins[symbol];
											const goToSymbol = () => this.goToDetails(symbol);
											const commonCellProps = !account
												? {}
												: { onClick: goToSymbol };
											return (
												<tr
													className="hoverable pointer table-row table-bottom-border"
													key={index}
												>
													<td />
													<td onClick={goToSymbol} className="td-name td-fit">
														<div className="d-flex align-items-center">
															<Coin icon_url={logo} />
															<div className="px-2">
																{isMobile ? '' : fullname}
																<span className="pl-2 secondary-text">
																	{display_name}
																</span>
															</div>
														</div>
													</td>
													<td {...commonCellProps}>
                                                        {this.renderBalanceInWallet()}
													</td>
													<td {...commonCellProps}>
															{/* {totalUserStakes[symbol]} */}
                                                            {formatToCurrency(depositInfo?.[0] ?? 0, min ?? 5)}
													</td>
													<td {...commonCellProps}>
															<Variable apr={apr} className="important-text" />
													</td>
													<td {...commonCellProps}>
															{/* {totalUserEarnings[symbol]} */}
                                                            {formatToCurrency(depositInfo?.[1] ?? 0, min ?? 5)}
													</td>
													<td>
														<div className="d-flex">
															<AntBtn
																className="stake-btn caps"
																type="primary"
																ghost
																onClick={() =>
																	this.startStakingProcess(tokenData)
																}
																disabled={!account || networksMismatch}
															>
																{STRINGS['STAKE_LIST.MANAGE']}
															</AntBtn>
														</div>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
}

const mapStateToProps = (store) => {
    return {
        coins: store.app.coins,
        balance: store.user.balance,
    };
};

const mapDispatchToProps = (dispatch) => ({
	setNotification: bindActionCreators(setNotification, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withConfig(withRouter(Stake)));
