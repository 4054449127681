import React, { Fragment } from 'react';
import { EditWrapper, IconTitle, Button, ActionNotification } from 'components';
import STRINGS from 'config/localizedStrings';
import withConfig from 'components/ConfigProvider/withConfig';
import { Button as AntBtn } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const Manage = ({ onClickStake, onClose, onClickUnstake, onClickClaim, onClickCompound }) => {
	return (
		<Fragment>
            <ActionNotification
				text={
					<CloseOutlined
						style={{ fontSize: '24px' }}
						className="action_notification-image secondary-text"
					/>
				}
				onClick={onClose}
				className="close-button p-2"
			/>
			<div className="dialog-content">
				<IconTitle
					text={''}
					textType="stake_popup__title"
					underline={false}
					className="w-100"
				/>
			</div>
			<div className="dialog-content bottom">
				<div className="text-align-center secondary-text">
					<EditWrapper stringId="STAKE.CHECKING_ALLOWANCE">
                        <AntBtn
                            className="stake-btn caps"
                            type="primary"
                            ghost
                            onClick={onClickStake}
                            // disabled={false}
                        >
                            {STRINGS['STAKE_LIST.STAKE']}
                        </AntBtn>
					</EditWrapper>
				</div>
			</div>
            <div className="dialog-content bottom">
				<div className="text-align-center secondary-text">
					<EditWrapper stringId="STAKE.CHECKING_ALLOWANCE">
                        <AntBtn
                            className="stake-btn caps"
                            type="primary"
                            ghost
                            onClick={onClickUnstake}
                            // disabled={false}
                        >
                            {STRINGS['STAKE_LIST.UNSTAKE']}
                        </AntBtn>
					</EditWrapper>
				</div>
			</div>
            <div className="dialog-content bottom">
				<div className="text-align-center secondary-text">
					<EditWrapper stringId="STAKE.CHECKING_ALLOWANCE">
                        <AntBtn
                            className="stake-btn caps"
                            type="primary"
                            ghost
                            onClick={onClickClaim}
                            // disabled={false}
                        >
                            {STRINGS['STAKE_LIST.CLAIM_REWARD']}
                        </AntBtn>
					</EditWrapper>
				</div>
			</div>
            <div className="dialog-content bottom">
				<div className="text-align-center secondary-text">
					<EditWrapper stringId="STAKE.CHECKING_ALLOWANCE">
                        <AntBtn
                            className="stake-btn caps"
                            type="primary"
                            ghost
                            onClick={onClickCompound}
                            // disabled={false}
                        >
                            {STRINGS['STAKE_LIST.COMPOUND']}
                        </AntBtn>
					</EditWrapper>
				</div>
			</div>
            {/* <div className="dialog-content">
				<IconTitle
					text={''}
					textType="stake_popup__title"
					underline={false}
					className="w-100"
				/>
			</div> */}
		</Fragment>
	);
};

export default withConfig(Manage);
