import React from 'react';
import classnames from 'classnames';
import STRINGS from 'config/localizedStrings';
import { FLEX_CENTER_CLASSES } from 'config/constants';
import { Input, message } from 'antd';
import withConfig from 'components/ConfigProvider/withConfig';
import { EditWrapper } from 'components';
import { wagmiUseAccountHOC } from 'utils/wagmiWrapper';
import copy from 'copy-to-clipboard';

const BUTTONS_CLASSES = [
	'buttons-section--button',
	...FLEX_CENTER_CLASSES,
	'holla-button',
];

const Referral = ({ account }) => {
    const [address, setAddress] = React.useState(account?.address);

    const onClick = () => {
        const referralTemplate = 'https://' + document.domain + '?referral=' + address;
        copy(referralTemplate);
        message.success('Referral link copyed to clipboard!');
    };

    React.useEffect(()=>{
        setAddress(account?.address);
    }, [account.address]);

	return (
		<div
			className={classnames(
				...FLEX_CENTER_CLASSES,
				'flex-column',
				'section_1-content'
			)}
		>
			<div className={classnames('f-1', ...FLEX_CENTER_CLASSES, 'flex-column')}>
				<div className="home-title text-capitalize">
					<EditWrapper stringId="REFERRAL_LINK.TITLE">
						{STRINGS['REFERRAL_LINK.TITLE']}
					</EditWrapper>
				</div>
				<div className="text-section text-center">
					<EditWrapper stringId="REFERRAL_LINK.INFO_TEXT">
						{STRINGS['REFERRAL_LINK.INFO_TEXT']}
					</EditWrapper>
				</div>

                <Input 
                    style={{
                        margin: '2rem'
                    }}
                    value={address}
                    placeholder={STRINGS['REFERRAL_LINK.INPUT_PLACEHOLDER']} 
                    defaultValue={account?.address}
                    onChange={(ret)=>{setAddress(ret?.target?.value)}}
                />

				<div className={classnames('buttons-section', ...FLEX_CENTER_CLASSES)}>
                    <div style={{ display: 'flex', alignSelf: 'center' }}>
						<EditWrapper stringId="REFERRAL_LINK.COPY_LINK_BUTTON" />
						<div
							className={classnames(
								...BUTTONS_CLASSES,
								'main-section_button_invert',
								'no-border',
								{
									pointer: onClick,
								}
							)}
							onClick={onClick}
						>
							<EditWrapper>{STRINGS['REFERRAL_LINK.COPY_LINK_BUTTON']}</EditWrapper>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withConfig(wagmiUseAccountHOC(Referral));
